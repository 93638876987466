import UnitCo2e from '@src/components/unit-co2e';
import { formatNumber } from '@src/utils/format-number';
import { useTranslation } from 'react-i18next';

const AccommodationEmission = ({ guests, night, ef }: { guests?: number; night?: number; ef?: number }) => {
    const { t } = useTranslation();

    const totalCarbonFootprint = (guests || 0) * (night || 0) * (ef || 0);
    return (
        <div className="mt-4">
            <div className="flex justify-between items-center font-normal">
                <div className=" text-black"> {t('DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_PERSON')}</div>
                <div className=" text-green-600">
                    <span className="px-1">{formatNumber(guests ?? 0)}</span>
                    {t('DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_PERSON_UNIT')}
                </div>
            </div>
            <div className="flex justify-between items-center font-normal">
                <div className=" text-black"> {t('DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT')}</div>
                <div className=" text-green-600">
                    <span className="px-1">{formatNumber(night ?? 0)}</span>
                    {t('DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_UNIT')}
                </div>
            </div>
            <div className="flex justify-between items-center font-semibold">
                <div className=" text-black"> {t('DETAIL_PAGE.CARBON_FOOTPRINT')}</div>
                <div className=" text-black flex">
                    {totalCarbonFootprint ? totalCarbonFootprint?.toFixed(2) : 0} <keygen />
                    <UnitCo2e />
                </div>
            </div>
        </div>
    );
};

export default AccommodationEmission;
