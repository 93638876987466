import { CloudUploadOutlined } from '@ant-design/icons';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import Iconify from '@src/components/layout/admin/components/iconify';
import api from '@src/services/axios';
import { useDeleteEvidenceById, useEvidenceEventsById } from '@src/services/queries/useEvidencesQuery';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { ProgressProps, UploadProps } from 'antd';
import { Progress, Upload } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './attachment.css';

const twoColors: ProgressProps['strokeColor'] = {
    '0%': '#108ee9',
    '100%': '#87d068',
};

export interface IEvidence {
    name: string;
    type: string;
    urls: string[];
    name_ch: string;
    name_en: string;
}

export default function Attachment({ eventId }: { eventId: number }) {
    const queryClient = useQueryClient();
    const deleteEvidenceByIdQuery = useDeleteEvidenceById();
    const { t } = useTranslation();
    const { Dragger } = Upload;
    const [evidenceType, setEvidenceType] = useState<string>('');
    const [progress, setProgress] = useState(0);
    const [fileList, setFileList] = useState<Array<any>>([]);
    const [listEvidences, setListEvidences] = useState<Array<any>>([]);
    const [evidenceId, setEvidenceId] = useState<number>();
    const [evidenceByType, setEvidenceByType] = useState<IEvidence>();
    const navigate = useNavigate();

    const evidenceEventQuery = useEvidenceEventsById(eventId);
    const uploadMutation = useMutation({
        mutationFn: async (formData: FormData) => {
            try {
                const response = await api.post('/evidences', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent: any) => {
                        if (progressEvent && progressEvent.loaded && progressEvent.total) {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setProgress(percentCompleted); // Update the progress state
                        }
                    },
                });
                if (response && response.status === 201) {
                    setProgress(0);
                    queryClient.invalidateQueries({ queryKey: ['EvidenceEventById', eventId] });
                }
                return response;
            } catch (error: any) {
                setProgress(0);
                const { statusCode, message } = error.response.data;
                if (statusCode && statusCode === 400) {
                    Swal.fire({
                        icon: 'error',
                        title: message,
                    });
                }
                console.table('Error uploading evidence:', error);
                // throw error;
            }
        },
    });

    // const filesWithUrls = useMemo(() => {
    //     return listEvidences.filter(file => file.urls.length > 0);
    // }, [listEvidences]);

    const handleUploadEvidence = (options: any) => {
        const { file } = options;
        const formData = new FormData();
        // key form state
        formData.append('eventId', eventId.toString());
        formData.append(`${evidenceType}`, file);
        uploadMutation.mutateAsync(formData);
        try {
        } catch (error: any) {
            console.error(error);
        }
    };
    const props: UploadProps = {
        name: 'file',
        multiple: true,
        customRequest: handleUploadEvidence,
    };

    function handleSelectUpload(item: any) {
        setEvidenceType(item.type);
        setFileList(item.urls);
    }

    const handleRemoveEvidenceId = async (url: string) => {
        const response = await deleteEvidenceByIdQuery.mutateAsync({ evidenceId: evidenceId as number, url: url, type: evidenceType as string });
        if (response && response.status === 200) {
            queryClient.invalidateQueries({ queryKey: ['EvidenceEventById', eventId] });
        }
    };

    const updateFilesFilterSelectType = useCallback(
        (fileEvidences: any) => {
            //@ts-ignore
            const evidenceOfType = fileEvidences.find(fileEvidence => fileEvidence.type === evidenceType);
            setEvidenceByType(evidenceOfType);
            setFileList(evidenceOfType.urls);
        },
        [evidenceType],
    );

    useEffect(() => {
        if (evidenceEventQuery?.data) {
            setEvidenceId(evidenceEventQuery.data.data.id);
            setListEvidences(evidenceEventQuery.data.data?.files);
        }
        if (evidenceEventQuery?.data && evidenceType && evidenceEventQuery.data.data?.files) {
            updateFilesFilterSelectType(evidenceEventQuery.data.data?.files);
        }
    }, [evidenceEventQuery?.data, evidenceType, updateFilesFilterSelectType]);

    const getViewByType = (url: string) => {
        if (url && url.endsWith('.pdf')) {
            return <Iconify icon="teenyicons:pdf-solid" width={40} sx={{ color: 'red' }} />;
        } else {
            return <img className="upload-list-item-thumbnail" alt="not found" src={url} />;
        }
    };

    const handleNext = () => {
        let tmpCreateData = {
            status: 'post',
            paging: 8,
        };
        sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));
        navigate(`/register/post/${tmpCreateData.paging}`);
    };

    return (
        <div className="box" style={{ marginBlockStart: '45px', borderRadius: '90px' }}>
            <div className="columns is-mobile">
                <div className="column is-5" style={{ marginBlockStart: '-10px', marginInlineStart: '70px' }}>
                    <br />
                    <h1 className="content-header font-bold content-left">{t('ATTACHMENT.HEADER')}</h1>
                </div>
            </div>
            <div className="columns is-mobile">
                <div className="column is-5">
                    <div className="columns">
                        <div className="column is-9" style={{ marginInlineStart: '70px' }}>
                            <h4 className="content-left font-bold">{t('ATTACHMENT.SOURCE_CO2E')}</h4>
                        </div>
                        <div className="column is-3">
                            <h4 className="content-left font-bold" style={{ marginLeft: '-15px' }}>
                                {t('ATTACHMENT.FILE_AMOUNT')}
                            </h4>
                        </div>
                    </div>
                    {/* eslint-disable jsx-a11y/anchor-is-valid  */}
                    {listEvidences &&
                        listEvidences.map((evidence: any, index: number) => {
                            return (
                                <a
                                    key={index}
                                    className={`box b-attachment ${evidence.type === evidenceType ? 'b-is-info' : ''}`}
                                    onClick={() => handleSelectUpload(evidence)}
                                >
                                    <div className="columns is-mobile">
                                        <div className="column is-9" style={{ marginInlineStart: '30px', marginTop: '-7px' }}>
                                            <h4 className="content-left">{t(evidence.name)}</h4>
                                        </div>
                                        <div className="column is-3" style={{ marginInlineStart: '20px', marginTop: '-7px' }}>
                                            <p className="content-left">{evidence?.urls?.length ?? 0}</p>
                                        </div>
                                    </div>
                                </a>
                            );
                        })}
                </div>
                <div style={{ marginInlineStart: '50px', borderLeft: '2px solid #A6A6A6' }}></div>
                <div className="column is-6" style={{ marginInlineStart: '30px', paddingRight: '55px', marginTop: '-50px' }}>
                    {evidenceType ? (
                        <div className="box">
                            <div className="columns">
                                <div className="column is-1">
                                    <div
                                        className="box"
                                        style={{
                                            backgroundColor: '#E8E5E4',
                                            height: '40px',
                                            width: '13px',
                                            paddingTop: '10px',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <i className={'fas fa-file'} style={{ fontSize: 17, marginLeft: '-8px' }}></i>
                                    </div>
                                </div>
                                <div className="column is-8" style={{ marginInlineStart: '20px', marginTop: '7px' }}>
                                    <p className="content-left font-bold">{evidenceByType && evidenceByType.name}</p>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column">
                                    <Dragger {...props} accept="image/png, image/jpg, image/jpeg, application/pdf" showUploadList={false}>
                                        <p className="ant-upload-drag-icon">
                                            <CloudUploadOutlined />
                                        </p>
                                        <p className="ant-upload-text">{t('ATTACHMENT.UPLOAD_CONTENT')}</p>
                                        <button className="button" type="button">
                                            {t('BUTTONS.UPLOAD')}
                                        </button>
                                    </Dragger>
                                </div>
                            </div>
                            <p className="content-left mb-4" style={{ marginTop: '-15px' }}>
                                {t('ATTACHMENT.UPLOAD_TITLE')}
                                <span className="has-text-danger">*</span>
                            </p>

                            {fileList.length > 0 && (
                                <p className="content-left mb-4">
                                    {t('ATTACHMENT.TITLE')} {fileList.length} {t('LIST')}
                                </p>
                            )}

                            <div className={fileList.length! > 3 ? 'detail-scroll-attachment' : 'detail-attachment'}>
                                {progress ? (
                                    <div className="columns" style={{ marginTop: '-20px' }}>
                                        <Progress percent={progress} strokeColor={twoColors} size="small" />
                                    </div>
                                ) : (
                                    ''
                                )}

                                {fileList &&
                                    fileList.map((item: any, index: number) => {
                                        const resultName = item.replace(`${process.env.REACT_APP_API_URL}/assets/`, '');
                                        return (
                                            <div className={'box b-list-attachment '} key={index}>
                                                <div className="flex justify-between">
                                                    <div className="flex items-center gap-x-2  ">
                                                        <div style={{ position: 'relative', height: '35px' }}>{getViewByType(item)}</div>
                                                        <div className="overflow-hidden max-w-[380px]">
                                                            <Tooltip title={resultName} placement="top">
                                                                <p className=" truncate"> {resultName}</p>
                                                            </Tooltip>
                                                        </div>
                                                    </div>

                                                    <div className=" is-1" onClick={() => handleRemoveEvidenceId(item)}>
                                                        <DeleteIcon className="upload-list-item-actions " />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <div className="column is-2 is-offset-9">
                <button
                    onClick={handleNext}
                    className="button is-fullwidth App"
                    style={{ backgroundColor: '#B5D224', fontSize: '18px', height: '50px', marginInlineStart: '45px' }}
                >
                    {t('BUTTONS.NEXT')}
                </button>
            </div>
        </div>
    );
}
