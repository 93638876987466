import { listUploadImg } from '@src/constants/screen/util';
import { useDeleteImagesById, useImagesEventsById } from '@src/services/queries/useImagesQuery';
import { useUploadQuery } from '@src/services/queries/useUploadQuery';
import { useQueryClient } from '@tanstack/react-query';
import type { UploadProps } from 'antd';
import { message, Upload as Uploaded } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './upload.css';
import ImageUpload from './Upload/imageUpload';

export default function Upload({ eventId }: { eventId: number }) {
    const queryClient = useQueryClient();
    const imagesEventsQuery = useImagesEventsById(eventId);
    const uploadQuery = useUploadQuery();
    const deleteImagesQuery = useDeleteImagesById();
    const navigate = useNavigate();
    const { Dragger } = Uploaded;
    const { t } = useTranslation();

    const handleFileUpload = async (options: any) => {
        try {
            const { file } = options;
            const formData = new FormData();
            formData.append('name', file.name);
            formData.append('eventId', eventId.toString());
            formData.append('files', file);
            const data = await uploadQuery.mutateAsync(formData);
            if (data.status === 201) {
                message.success(`${file.name} uploaded successfully.`);
                queryClient.invalidateQueries({ queryKey: ['ImagesEventById', eventId] });
            } else {
                message.error(`${file.name} upload failed.`);
            }
        } catch (error) {
            console.error('Upload failed:', error);
        }
    };

    const handleRemoveImageById = async (imageId: number) => {
        try {
            const data = await deleteImagesQuery.mutateAsync(imageId);
            if (data.status === 200) {
                message.success('Remove image successfully!');
                queryClient.invalidateQueries({ queryKey: ['ImagesEventById', eventId] });
            } else {
                message.error('Remove image failed!');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const props: UploadProps = {
        name: 'files',
        multiple: true,
        customRequest: handleFileUpload,
    };
    const handleNext = () => {
        let tmpCreateData = {
            status: 'post',
            paging: 7,
        };
        sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));
        navigate('/register/post/7');
    };

    return (
        <div className="box" style={{ marginBlockStart: '80px', borderRadius: '90px' }}>
            <div className="columns is-mobile">
                <div className="column is-5 is-offset-1" style={{ marginBlockStart: '20px' }}>
                    <i className="fas fa-image" style={{ fontSize: '50px' }}></i>
                    <br />
                    <h1 className="content-header font-bold">{t('UPLOAD_PAGE.HEADER')}</h1>
                    <p className="">
                        <span className="has-text-danger">*</span>&nbsp;{t('UPLOAD_PAGE.TITLE')}
                    </p>
                    <br />
                    <div className="column">
                        <Dragger {...props} accept={listUploadImg} showUploadList={false} style={{ borderRadius: '25px' }}>
                            <p className="ant-upload-drag-icon mt-24">
                                <i className="fas fa-upload" style={{ fontSize: '50px' }}></i>
                            </p>
                            <span className="file-label content-center" style={{ height: '180px', fontSize: '18px' }}>
                                {t('UPLOAD_PAGE.FILE_TYPE')}
                            </span>
                        </Dragger>
                    </div>
                    <br />
                    <br />
                </div>
                <div className="column is-5" style={{ marginBlockStart: '20px' }}>
                    <div className="w-full max-w-md mx-auto p-4 ">
                        <h2 className="text-lg font-semibold mb-4">
                            <div className="content-sub-header content-left">{t('UPLOAD_PAGE.FILE_UPLOAD')}</div>
                        </h2>
                        <div className="grid grid-cols-3 gap-4 max-h-[40vh] h-[40vh] overflow-y-auto p-2">
                            {imagesEventsQuery &&
                                imagesEventsQuery?.data &&
                                imagesEventsQuery?.data?.length > 0 &&
                                imagesEventsQuery?.data.map((item: any) => (
                                    <div className="col" key={item.id}>
                                        <ImageUpload url={item.url} onRemove={() => handleRemoveImageById(item.id)} />
                                    </div>
                                ))}
                        </div>
                        <button
                            onClick={handleNext}
                            className="button is-large is-fullwidth App"
                            style={{ backgroundColor: '#B5D224', fontSize: '18px', height: '70px' }}
                            disabled={imagesEventsQuery?.data?.length === 0}
                        >
                            {t('BUTTONS.NEXT')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
