import { Box } from '@mui/material';
import UnitCo2e from '@src/components/unit-co2e';
import icon from '@src/constants/img/carbon_logo.jpeg';
import { useGlobalState } from '@src/context/GlobalStateContext';
import { useCreatePerEventQuery, useUpdatePerEventQuery } from '@src/services/queries/useEventsQuery';
import { EmissionsSource } from '@src/types';
import { formatNumber } from '@src/utils/format-number';
import { addPreEventGuest, updatePreEventByIdGuest } from '@src/utils/state-guest';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import Swal from 'sweetalert2';

const GasSummary = ({ TotalMembers, modeEvent }: { modeEvent: string; TotalMembers: number }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { event } = useParams();

    const { gasEmissions, formMice, setFormMice, setGasEmissions } = useGlobalState();

    const createPerEventQuery = useCreatePerEventQuery();
    const updatePerEventQuery = useUpdatePerEventQuery();

    const { totalCarbonFootprint } = useMemo(() => {
        return (gasEmissions || []).reduce(
            (totals: any, source: EmissionsSource) => {
                totals.totalCarbonFootprint += source.total / 1000;
                return totals;
            },
            { totalCarbonFootprint: 0 },
        );
    }, [gasEmissions]);

    const totalCarbonFootprintPercentage = totalCarbonFootprint / TotalMembers;

    const handlePostEvent = () => {
        let tmpCreateDataStr = sessionStorage.getItem('tmpCreateData');
        let tmpCreateData = JSON.parse(tmpCreateDataStr!);
        tmpCreateData.status = event;
        tmpCreateData.paging = 6;
        sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));
        navigate(`/register/${event}/${tmpCreateData.paging}`);
    };
    const updatePostEvent = async () => {
        const newGasEmissions: EmissionsSource[] =
            gasEmissions?.map((item: EmissionsSource) => {
                item.percentage = (item.percentage / totalCarbonFootprint) * 100;
                return item;
            }) || [];
        const send = {
            ...formMice,
            eventMemberOnline: parseInt(formMice?.eventMemberOnline as string),
            eventForeignScale: parseInt(formMice?.eventForeignScale as string),
            emission: {
                summary: totalCarbonFootprint,
                summary_person: totalCarbonFootprintPercentage,
                emissions_sources: newGasEmissions,
            },
        };
        let dataEventID = sessionStorage.getItem('tmpEventId');
        const stateEventID = dataEventID ? parseInt(JSON.parse(dataEventID)) : 0;
        await updatePerEventQuery.mutateAsync({
            eventId: stateEventID,
            preEvent: send,
        });
    };

    const clearStateGuestEvent = () => {
        localStorage.removeItem('formMice');
        localStorage.removeItem('gasEmissions');
        sessionStorage.removeItem('tmpEventId');
        setFormMice(undefined as any);
        setGasEmissions(undefined as any);
        navigate(`/guest-dashboard`);
    };

    const saveTempGuestEvent = () => {
        const send = {
            ...formMice,
            eventMemberOnline: parseInt(formMice?.eventMemberOnline as string),
            eventForeignScale: parseInt(formMice?.eventForeignScale as string),
            emission: {
                summary: totalCarbonFootprint,
                summary_person: totalCarbonFootprintPercentage,
                emissions_sources: gasEmissions,
            },
        };

        if (event === 'new') {
            send.id = Date.now();
            //@ts-ignore
            send.createdAt = new Date();
            addPreEventGuest(send);
            clearStateGuestEvent();
        } else {
            let dataEventID = sessionStorage.getItem('tmpEventId');
            const stateEventID = dataEventID ? parseInt(JSON.parse(dataEventID)) : 0;
            send.id = stateEventID;
            updatePreEventByIdGuest(stateEventID, send);
            clearStateGuestEvent();
        }
    };

    const onSavePerEvent = () => {
        if (modeEvent === 'post') {
            handlePostEvent();
            // updatePostEvent();
        } else {
            Swal.fire({
                iconHtml: `<img src='${icon}' >`,
                html: `
            <div class="text-2xl font-medium mt-6">
            ${i18n.language === 'en' ? 'submit report' : i18n.language === 'cn' ? '提交报告' : 'ส่งรายงาน'}
                    <div>
                              ${i18n.language === 'en' ? 'Submit actual calculation results for carbon offset request' : i18n.language === 'cn' ? '提交实际计算结果申请碳补偿。' : 'ส่งผลการคำนวณจริงเพื่อขอชดเชยคาร์บอน'}
                    </div>
                </div>
            `,
                // icon: 'info',
                showCancelButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonColor: '#B5D224',
                cancelButtonColor: '#d33',
                cancelButtonText: `${t('BUTTONS.CANCEL')}`,
                confirmButtonText: `${t('BUTTONS.COMFIRM')}`,
                customClass: {
                    popup: 'popup-backend',
                },
            }).then(({ isConfirmed }) => {
                if (isConfirmed)
                    if (modeEvent === 'new' || modeEvent === 'edit') {
                        //save per event to to localStorage
                        saveTempGuestEvent();
                    } else {
                        //save per event to database
                        sendPerEventAPI();
                    }
            });
        }
    };

    const clearState = () => {
        localStorage.removeItem('formMice');
        localStorage.removeItem('gasEmissions');
        sessionStorage.removeItem('tmpEventId');
        setFormMice(undefined as any);
        setGasEmissions(undefined as any);
        // next to download logo
        let tmpCreateDataStr = sessionStorage.getItem('tmpCreateData');
        let tmpCreateData = JSON.parse(tmpCreateDataStr!);
        tmpCreateData.status = event;
        tmpCreateData.paging = 5;
        sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));
        navigate(`/register/${event}/${tmpCreateData.paging}`);
    };

    const sendPerEventAPI = async () => {
        const newGasEmissions: EmissionsSource[] =
            gasEmissions?.map((item: EmissionsSource) => {
                item.percentage = (item.percentage / totalCarbonFootprint) * 100;
                return item;
            }) || [];
        const send = {
            ...formMice,
            subCategoryId: parseInt(formMice?.subCategoryId as string),
            eventMemberOnline: parseInt(formMice?.eventMemberOnline as string),
            eventForeignScale: parseInt(formMice?.eventForeignScale as string),
            emission: {
                summary: totalCarbonFootprint,
                summary_person: totalCarbonFootprintPercentage,
                emissions_sources: newGasEmissions,
            },
        };
        send.startDate = moment(formMice?.startDate as string).format('YYYY-MM-DD');
        send.endDate = moment(formMice?.endDate as string).format('YYYY-MM-DD');

        let dataEventID = sessionStorage.getItem('tmpEventId');
        const stateEventID = dataEventID ? parseInt(JSON.parse(dataEventID)) : 0;

        const response =
            modeEvent === 'pre'
                ? await createPerEventQuery.mutateAsync(send)
                : await updatePerEventQuery.mutateAsync({
                      eventId: stateEventID,
                      preEvent: send,
                  });
        //clear state
        if (response.status === 201 && modeEvent === 'pre') {
            Swal.fire({
                icon: 'success',
                html: `
                <div class="text-2xl font-medium mt-6">
                ${i18n.language === 'en' ? 'successfully!' : i18n.language === 'cn' ? '資料保存成功' : 'บันทึกข้อมูลสำเร็จ!'}
               </div>
                `,
                allowOutsideClick: false,
                allowEscapeKey: false,
                customClass: {
                    popup: 'popup-backend',
                },
            });
            clearState();
        } else if (modeEvent === 'pre-update' && response.status === 200) {
            Swal.fire({
                icon: 'success',
                html: `
                <div class="text-2xl font-medium mt-6">
                ${i18n.language === 'en' ? 'Update Successfully!' : i18n.language === 'cn' ? '已成功儲存編輯資訊' : 'บันทึกข้อมูลแก้ไขสำเร็จ!'}
               </div>
                `,
                allowOutsideClick: false,
                showConfirmButton: false,
                allowEscapeKey: false,
                timer: 2000,
                customClass: {
                    popup: 'popup-backend',
                },
            }).then(() => {
                clearState();
            });
        } else {
            Swal.fire({
                icon: 'error',
                html: `
                <div class="text-2xl font-medium mt-6">
                ${i18n.language === 'en' ? 'An error occurred!' : i18n.language === 'cn' ? '發生錯誤！' : 'เกิดข้อผิดพลาด!'}
                        <div>
                                  ${i18n.language === 'en' ? 'Unable to save data' : i18n.language === 'cn' ? '無法保存數據' : 'ไม่สามารถบันทึกข้อมูลได้'}
                        </div>
                    </div>
                `,
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonColor: '#d33',
                confirmButtonText: `${t('BUTTONS.CANCEL')}`,
                customClass: {
                    popup: 'popup-backend',
                },
            });
        }
    };
    return (
        <div className="container  text-black text-left mt-36">
            <div className="flex justify-center items-center">
                <div className="bg-white rounded-3xl shadow-2xl py-6 px-12 w-full max-w-6xl">
                    <div className="py-5 pl-4">
                        <h1 className="content-header content-left">{t('SUMMARY_PAGE.HEADER')}</h1>
                    </div>

                    <div className="flex flex-row p-4">
                        <div className="w-full  px-4">
                            <div className="flex justify-between mt-1">
                                <h1 className="content-sub-header content-left font-semibold">{t('SUMMARY_PAGE.SOURCE_CO2E')}</h1>
                                <h1 className="content-sub-header content-left font-semibold">
                                    <div className="flex items-center">
                                        {t('SUMMARY_PAGE.SOURCE_CO2E_AMT')}
                                        <UnitCo2e unitStart="CO" unitEnd="e" />
                                        {t('SUMMARY_PAGE.SOURCE_AMT')}
                                    </div>
                                </h1>
                            </div>
                            {gasEmissions?.map((gas: any, index: number) => (
                                <ListGasEmissions
                                    key={index}
                                    name={gas.name}
                                    total={gas.total}
                                    percentage={gas.percentage}
                                    totalCarbonFootprint={totalCarbonFootprint}
                                />
                            ))}
                        </div>

                        <div className="w-1/2 ">
                            <Box
                                display="block"
                                alignItems="center"
                                sx={{
                                    border: '5px solid grey',
                                    borderColor: '#B5D224',
                                    borderRadius: '12px',
                                    p: 2,
                                    mb: 2,
                                    textAlign: 'center',
                                }}
                            >
                                <h1 className="content-header-s !text-2xl">{t('SUMMARY_PAGE.TOTAL_CO2E')}</h1>
                                <h1 className="content-header font-bold " style={{ color: '#26B3F0' }}>
                                    {formatNumber(totalCarbonFootprint, 2)}
                                    {/* {Math.ceil(totalCarbonFootprint)} */}

                                    {t('SUMMARY_PAGE.UNIT')}
                                </h1>
                                <h1 className="content-header-s">{t('SUMMARY_PAGE.AVG_PER_PERSON')}</h1>
                                <h1
                                    className="content-header font-bold
                                    drop-shadow-2xl
                                    "
                                    style={{ color: '#26B3F0' }}
                                >
                                    {formatNumber(totalCarbonFootprintPercentage, 2)}
                                    {/* {Math.ceil(totalCarbonFootprintPercentage)} */}
                                    {t('SUMMARY_PAGE.UNIT')}
                                </h1>
                            </Box>
                            <button
                                className="button is-success is-large
                                 is-full-width rounded-lg w-full"
                                style={{
                                    backgroundColor: '#B5D224',
                                    fontSize: '20px',
                                    height: '70px',
                                }}
                                onClick={onSavePerEvent}
                            >
                                {modeEvent === 'post' ? t('BUTTONS.NEXT') : t('SUMMARY_PAGE.BUTTONS.PRE')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GasSummary;
export const ListGasEmissions = ({
    name,
    total,
    percentage,
    totalCarbonFootprint,
}: {
    name: string;
    total: number;
    percentage: number;
    totalCarbonFootprint: number;
}) => {
    return (
        <div className="grid col-span-2 mb-0">
            <div className="text-left">{name}</div>
            <div className="text-right">
                <div className="font-semibold text-right">
                    {formatNumber(total / 1000, 2)}
                    <span className="font-normal">
                        {' '}
                        ({formatNumber(percentage, 2)}
                        %)
                    </span>
                </div>
            </div>
        </div>
    );
};
