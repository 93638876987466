import { Button } from '@mui/material';
import UnitCo2e from '@src/components/unit-co2e';
import { useCreateCertificate } from '@src/services/queries/useCertificateQuery';
import { useEventsByIdQuery, useInvoiceByEventIdQuery, useInvoicePdfByEventIdQuery, useReceiptByEventIdQuery } from '@src/services/queries/useEventsQuery';
import { formatNumber } from '@src/utils/format-number';
import { FileCheck, Receipt } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ListGasEmissions } from '../Register/GasSummary';
import styles from './summary.module.css';
const ViewSummary = () => {
    const navigate = useNavigate();
    const { mutateAsync } = useCreateCertificate();
    const [isInvoice, setIsInvoice] = useState(false);

    const { mutateAsync: getInvoiceByEventId } = useInvoiceByEventIdQuery();
    const { mutateAsync: receiptByEventId } = useReceiptByEventIdQuery();
    const { mutateAsync: invoicePdfByEventId } = useInvoicePdfByEventIdQuery();

    const { t } = useTranslation();
    const { id } = useParams();
    const bgImage = `${process.env.PUBLIC_URL}/assets/images/pexels-kelly.jpg`;
    const { data } = useEventsByIdQuery(parseInt(id as string));
    const onBackHome = () => {
        navigate('/');
    };

    const getInvoice = useCallback(async () => {
        const response = await getInvoiceByEventId({ eventId: parseInt(id as string) });
        if (response && response.fullName && response.address && response.taxId) {
            setIsInvoice(true);
        }
    }, [getInvoiceByEventId, id]);

    const downloadReceipt = async (response: any, name_file: string) => {
        try {
            var blob = new Blob([response], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
            link.setAttribute('download', `${name_file}-${Date.now()}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error('Error downloading receipt:', error);
        }
    };

    const onClickDownloadReceipt = async () => {
        try {
            const response = await receiptByEventId({ eventId: parseInt(id as string) });
            downloadReceipt(response, 'zcarb-receipt');
        } catch (error) {
            console.error('Error downloading :', error);
        }
    };

    const onClickDownloadInvoice = async () => {
        try {
            const response = await invoicePdfByEventId({ eventId: parseInt(id as string) });
            downloadReceipt(response, 'zcarb-invoice');
        } catch (error) {
            console.error('Error downloading :', error);
        }
    };

    const onClickDownloadCertificate = async (lang: string) => {
        const response = await mutateAsync({ eventId: parseInt(id as string), lang: lang });

        if (response && response.url) {
            const fileUrl = response.url;
            const res = await fetch(fileUrl);
            if (!res.ok) {
                throw new Error('Failed to fetch the file.');
            }
            const blob = await res.blob();
            const link = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);

            link.href = objectUrl;
            link.setAttribute('download', `certificate-${lang}.png`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(objectUrl); // Release memory
        } else {
            console.error('No file URL provided.');
        }
    };

    useEffect(() => {
        getInvoice();
    }, [getInvoice]);
    return (
        <div
            className={`flex h-screen justify-center items-center bg-no-repeat bg-center bg-cover `}
            style={{
                backgroundImage: `url('${bgImage}')`,
            }}
        >
            <div className="container">
                <div className="mx-auto max-w-6xl w-full text-center bg-white  p-8 rounded-lg">
                    <div className="py-5 pl-4">
                        <h1 className="content-header content-left">{t('SUMMARY_PAGE.HEADER')}</h1>
                    </div>
                    <div className="grid grid-cols-3  pl-4">
                        <div className=" col-span-2">
                            <div className="grid grid-cols-2 mb-0">
                                <div className="">
                                    <h1 className="content-sub-header content-left font-semibold">{t('SUMMARY_PAGE.SOURCE_CO2E')}</h1>
                                </div>
                                <div className="content-right">
                                    <h1 className="content-sub-header font-semibold">
                                        <div className="flex item-center justify-end">
                                            {t('SUMMARY_PAGE.SOURCE_CO2E_AMT')}
                                            <UnitCo2e unitStart="CO" unitEnd="e" />
                                            {t('SUMMARY_PAGE.SOURCE_AMT')}
                                        </div>
                                    </h1>
                                </div>
                            </div>

                            <div className="px-2">
                                {data?.emission?.emissions_sources.map((gas: any, index: number) => (
                                    <ListGasEmissions
                                        key={index}
                                        name={gas.name}
                                        total={gas.total}
                                        percentage={gas.percentage}
                                        totalCarbonFootprint={data?.emission?.summary}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className=" col-span-1">
                            <div className="border-[5px] border-[#B5D224] rounded-[12px] p-2 py-4 mb-4">
                                <h1 className="content-header-s !text-2xl">{t('SUMMARY_PAGE.TOTAL_CO2E')}</h1>
                                <h1 className="content-header font-bold !text-4xl" style={{ color: '#26B3F0' }}>
                                    {formatNumber(data?.emission?.summary, 2)}
                                    {/* {Math.ceil(totalCarbonFootprint)} */}
                                    {t('SUMMARY_PAGE.UNIT')}
                                </h1>
                                <h1 className="content-header-s">{t('SUMMARY_PAGE.AVG_PER_PERSON')}</h1>
                                <h1
                                    className="content-header font-bold
                                    drop-shadow-2xl
                                    "
                                    style={{ color: '#26B3F0' }}
                                >
                                    {formatNumber(data?.emission?.summary_person, 2)}
                                    {/* {Math.ceil(totalCarbonFootprintPercentage)} */}
                                    {t('SUMMARY_PAGE.UNIT')}
                                </h1>
                            </div>
                            {/* eslint-disable jsx-a11y/anchor-is-valid  */}

                            <div>
                                <button
                                    disabled={data?.compensateStatus !== 'COMPENSATED'}
                                    className={`${styles.btnCertificate} button is-large w-full rounded-lg justify-start gap-1
                                    disabled:bg-gray-500
                                    `}
                                    onClick={() => onClickDownloadCertificate('th')}
                                >
                                    <FileCheck size={18} /> {t('DASHBOARD_PAGE.SUMMARY_DIALOG.BUTTON1')}
                                </button>
                                <button
                                    disabled={data?.compensateStatus !== 'COMPENSATED'}
                                    className={`${styles.btnCertificate} button is-large w-full
                                    disabled:bg-gray-500
                                     rounded-lg justify-start gap-1 `}
                                    onClick={() => onClickDownloadCertificate('en')}
                                >
                                    <FileCheck size={18} /> {t('DASHBOARD_PAGE.SUMMARY_DIALOG.BUTTON2')}
                                </button>

                                {isInvoice && (
                                    <button
                                        className={`${styles.btnReceipt}  button is-large  w-full rounded-lg justify-start gap-1`}
                                        onClick={() => onClickDownloadInvoice()}
                                    >
                                        <Receipt size={18} /> {t('INVOICE.INVOICE_RECEIPT')}
                                    </button>
                                )}

                                <button
                                    className={`${styles.btnReceipt}  button is-large  w-full rounded-lg justify-start gap-1`}
                                    onClick={() => onClickDownloadReceipt()}
                                >
                                    <Receipt size={18} /> {t('INVOICE.RECEIPT')}
                                </button>
                            </div>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={onBackHome}
                                className="rounded-lg  App is-success
                        "
                            >
                                <span className={'!font-kanit !text-lg py-1'}>{t('DOWNLOAD_LOGO_PAGE.BUTTONS.HOME')}</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewSummary;
