import { ReactNode, useEffect, useMemo } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';

// import Logo from "../../../constants/img/logo2.png";
import Logo from '@constants/img/logo-app.png';

import { config } from '@src/config';
import { removeCookie } from '@src/utils/remove-cookie';
import { Profile } from '@src/utils/state-profile';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import { usePathname } from '../../../hooks';
import { useResponsive } from '../../../hooks/use-responsive';
import Scrollbar from '../../scrollbar';
import { NAV } from './config-layout';
import navConfig from './config-navigation';
// ----------------------------------------------------------------------
interface Props {
    openNav: boolean; // Define the type of onOpenNav
    onCloseNav: () => void; // Define the type of onOpenNav
}
export default function Nav({ openNav, onCloseNav }: Props) {
    const pathname = usePathname();
    const { role } = Profile();
    const newMenuSlide = useMemo(() => {
        return navConfig.filter(item => item.role.includes(role));
    }, [role]);

    const upLg = useResponsive('up', 'lg', 'sm');

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const handleLogout = () => {
        Swal.fire({
            title: 'ต้องการ ออกจากระบบ หรือไม่?',
            icon: 'info',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'ยืนยัน',
            confirmButtonColor: 'rgb(26, 162, 56)',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            customClass: {
                popup: 'popup-backend',
            },
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                removeCookie(config.keyNameCookie);
                window.location.href = '/login';
            }
        });
    };
    const renderAccount = (
        <Box
            sx={{
                py: 2,
                display: 'flex',
                borderRadius: 1.5,
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    width: '100px',
                }}
            >
                <Avatar sx={{ m: 1, width: '100%', height: '100%' }} src={Logo} variant="square" alt="photoURL"></Avatar>
            </div>
        </Box>
    );

    const renderMenu = (
        <Stack component="nav" spacing={0.5} sx={{ px: 0 }}>
            {newMenuSlide.map(item => (
                <NavItem key={item.title} item={item} />
            ))}
        </Stack>
    );

    const renderLogout = (
        <Stack alignItems="center" spacing={3} sx={{ px: 3, pt: 3, borderRadius: 5, position: 'relative' }}>
            <Button onClick={handleLogout} variant="outlined" fullWidth size="large" color="success">
                ออกจากระบบ
            </Button>
        </Stack>
    );

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            {renderAccount}

            {renderMenu}

            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    );

    return (
        <Box
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV.WIDTH },
            }}
        >
            {upLg ? (
                <>
                    <Box
                        sx={{
                            height: 1,
                            position: 'fixed',
                            width: NAV.WIDTH,
                            backgroundColor: '#ffff',
                            borderRight: theme => `dashed 1px ${theme.palette.divider}`,
                        }}
                    >
                        {renderContent}
                        {renderLogout}
                    </Box>
                </>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    PaperProps={{
                        sx: {
                            width: NAV.WIDTH,
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}

// ----------------------------------------------------------------------
interface item {
    item: { title: string; path: string; icon: ReactNode };
}
function NavItem({ item }: item) {
    const navigate = useNavigate();
    const pathname = usePathname();
    function handleClick(path: string) {
        navigate(path);
    }
    const active = item.path === pathname;
    return (
        <ListItemButton
            onClick={() => handleClick(item.path)}
            sx={{
                minHeight: 35,
                borderRadius: 0.75,
                typography: 'body2',
                color: 'text.dark',
                textTransform: 'capitalize',
                fontWeight: 'fontWeightMedium',
                ...(active && {
                    color: '#B5D224',
                    fontWeight: 'fontWeightSemiBold',
                    bgcolor: theme => alpha(theme.palette.grey[600], 0.11),
                    '&:hover': {
                        bgcolor: theme => alpha(theme.palette.grey[200], 0.16),
                    },
                }),
            }}
        >
            <Box component="span" sx={{ width: 20, height: 20, mr: 2 }}>
                {item.icon}
            </Box>

            <Box component="span">{item.title} </Box>
        </ListItemButton>
    );
}
