import UnitCo2e from '@src/components/unit-co2e';
import { useTranslation } from 'react-i18next';

const FoodEmission = ({ set, ef }: { set?: number; ef?: number }) => {
    const { t } = useTranslation();

    const totalCarbonFootprint = (set ?? 0) * (ef ?? 0);
    return (
        <div className="mt-4">
            <div className="flex justify-between items-center font-normal">
                <div className=" text-black"> {t('DETAIL_PAGE.FOOD_DRINK_DETAIL.AMOUNT')}</div>
                <div className=" text-green-600">
                    <span className="px-1">{set || 0}</span>
                    {t('DETAIL_PAGE.FOOD_DRINK_DETAIL.AMOUNT_UNIT')}
                </div>
            </div>

            <div className="flex justify-between items-center font-semibold">
                <div className=" text-black"> {t('DETAIL_PAGE.CARBON_FOOTPRINT')}</div>
                <div className=" text-black flex">
                    {' '}
                    {totalCarbonFootprint ? totalCarbonFootprint?.toFixed(2) : 0}
                    <UnitCo2e />
                </div>
            </div>
        </div>
    );
};

export default FoodEmission;
