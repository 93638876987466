import UnitCo2e from '@src/components/unit-co2e';
import { formatNumber } from '@src/utils/format-number';
import { useTranslation } from 'react-i18next';

const TravelEmission = ({ passengers_total, distance_outbound_total, ef }: { passengers_total?: number; distance_outbound_total?: number; ef?: number }) => {
    const { t } = useTranslation();

    const totalCo2eq = (passengers_total || 0) * (distance_outbound_total || 0) * (ef || 0);
    return (
        <div className="mt-4">
            <div className="flex justify-between items-center font-normal">
                <div className=" text-black"> {t('DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT')}</div>
                <div className=" text-green-600">
                    <span className="px-1">{formatNumber(passengers_total ?? 0, 0)}</span>
                    {t('DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT_UNIT')}
                </div>
            </div>
            <div className="flex justify-between items-center font-normal">
                <div className=" text-black"> {t('DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT')}</div>
                <div className=" text-green-600">
                    <span className="px-1">{formatNumber(distance_outbound_total ?? 0)} </span>
                    {t('DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT_UNIT')}
                </div>
            </div>
            <div className="flex justify-between items-center font-semibold">
                <div className=" text-black"> {t('DETAIL_PAGE.CARBON_FOOTPRINT')}</div>
                <div className=" text-black flex">
                    {' '}
                    {totalCo2eq ? formatNumber(totalCo2eq, 2) : 0}
                    <UnitCo2e />
                </div>
            </div>
        </div>
    );
};

export default TravelEmission;
