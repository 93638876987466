import { Box, Button, Card, Stack, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useAddInvoiceByEventIdQuery, useInvoiceByEventIdQuery } from '@src/services/queries/useEventsQuery';
import { InvoiceFormData } from '@src/types';
import { FileText, FileX } from 'lucide-react';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import IconBack from '../Element/IconBack';

// Add this interface for the form data

const Invoice = ({ eventId }: { eventId: number }) => {
    const { t } = useTranslation();
    const [selectInvoice, setSelectInvoice] = React.useState('0');
    const { mutateAsync: addInvoice } = useAddInvoiceByEventIdQuery();
    const { mutateAsync: getInvoiceByEventId } = useInvoiceByEventIdQuery();

    // Add react-hook-form
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<InvoiceFormData>();

    const handleBack = () => {
        let tmpCreateData = {
            status: 'post',
            paging: 8,
        };
        sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));
        window.location.href = '/register/post/8';
    };

    const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
        setSelectInvoice(nextView);
    };

    const handleNext = () => {
        let tmpCreateData = {
            status: 'post',
            paging: 10,
        };
        sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));
        window.location.href = `/register/post/${tmpCreateData.paging}`;
    };
    // Add onSubmit function
    const onSubmit = async (data: InvoiceFormData) => {
        try {
            if (selectInvoice === '0') {
                await addInvoice({
                    eventId,
                    invoiceData: {
                        fullName: '',
                        taxId: '',
                        address: '',
                    },
                });

                handleNext();
            } else {
                await addInvoice({ eventId, invoiceData: data });
                // Show success alert
                await Swal.fire({
                    icon: 'success',
                    title: t('INVOICE.SUCCESS_TITLE'),
                    text: t('INVOICE.SUCCESS_MESSAGE'),
                    confirmButtonColor: '#27ae60',
                    showConfirmButton: false,
                    timer: 1500,
                });
                handleNext();
            }
        } catch (error) {
            console.error('Error submitting invoice:', error);
            // Show error alert
            await Swal.fire({
                icon: 'error',
                title: t('INVOICE.ERROR_TITLE'),
                text: t('INVOICE.ERROR_MESSAGE'),
                confirmButtonColor: '#c0392b',
                confirmButtonText: t('INVOICE.BUTTON_OK'),
            });
        }
    };

    const fetchInvoice = useCallback(async () => {
        try {
            const invoice = await getInvoiceByEventId({ eventId: eventId as number });
            if (invoice && invoice.fullName && invoice.taxId && invoice.address) {
                setSelectInvoice('1');
                setValue('fullName', invoice.fullName);
                setValue('taxId', invoice.taxId);
                setValue('address', invoice.address);
            }
        } catch (error) {
            console.log(error);
        }
        // setSelectInvoice(invoice ? '1' : '0');
    }, [eventId, getInvoiceByEventId, setValue]);

    useEffect(() => {
        fetchInvoice();
    }, [fetchInvoice]);
    return (
        <div className="w-full max-w-[800px] mx-auto mt-28">
            <Card className="p-8 !rounded-[20px] text-left">
                <h4 className="text-2xl font-semibold mb-2 flex items-center gap-2">
                    <IconBack onClick={handleBack} />
                    {t('INVOICE.INVOICE_TITLE')}
                </h4>
                <Stack justifyContent={'center'} direction={'row'} mt={2}>
                    <ToggleButtonGroup
                        orientation="vertical"
                        size="large"
                        color="primary"
                        value={selectInvoice}
                        exclusive
                        fullWidth
                        onChange={handleChange}
                        className="gap-y-2 text-left"
                    >
                        <ToggleButton className="!font-Kanit !rounded-lg h-16 !justify-start" value="0">
                            <Box className="text-lg flex items-center gap-1">
                                <FileX />
                                {t('INVOICE.NO_INVOICE_RECEIPT_REQUIRED')}
                            </Box>
                        </ToggleButton>
                        <ToggleButton className="!font-Kanit !rounded-lg h-16 !justify-start " value="1">
                            <Box className="text-lg flex items-center gap-1">
                                <FileText />
                                {t('INVOICE.INVOICE_RECEIPT_REQUIRED')}
                            </Box>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
                {selectInvoice === '1' && (
                    <Box className="mt-4">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={3}>
                                <Controller
                                    name="fullName"
                                    control={control}
                                    rules={{ required: t('INVOICE.ERROR_MSG.FULL_NAME') }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={t('INVOICE.FULL_NAME')}
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors.fullName}
                                            helperText={errors.fullName?.message}
                                            sx={{
                                                '& .MuiOutlinedInput-root': { borderRadius: '10px' },
                                                '& .MuiInputBase-input': { fontFamily: 'Kanit, sans-serif' },
                                                '& .MuiInputLabel-root': { fontFamily: 'Kanit, sans-serif' },
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    name="taxId"
                                    control={control}
                                    rules={{
                                        required: t('INVOICE.ERROR_MSG.TAX_ID'),
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: t('INVOICE.ERROR_MSG.TAX_ID_NUMBER_ONLY'),
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={t('INVOICE.TAX_ID')}
                                            variant="outlined"
                                            fullWidth
                                            error={!!errors.taxId}
                                            helperText={errors.taxId?.message}
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 13 }}
                                            onChange={e => {
                                                const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                                                field.onChange(onlyNums);
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': { borderRadius: '10px' },
                                                '& .MuiInputBase-input': { fontFamily: 'Kanit, sans-serif' },
                                                '& .MuiInputLabel-root': { fontFamily: 'Kanit, sans-serif' },
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    name="address"
                                    control={control}
                                    rules={{ required: t('INVOICE.ERROR_MSG.ADDRESS') }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={t('INVOICE.ADDRESS')}
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            rows={3}
                                            error={!!errors.address}
                                            helperText={errors.address?.message}
                                            sx={{
                                                '& .MuiOutlinedInput-root': { borderRadius: '10px' },
                                                '& .MuiInputBase-input': { fontFamily: 'Kanit, sans-serif' },
                                                '& .MuiInputLabel-root': { fontFamily: 'Kanit, sans-serif' },
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                        </form>
                    </Box>
                )}

                <Stack justifyContent={'center'} direction={'row'} spacing={2} mt={7}>
                    <Button
                        variant="contained"
                        className="!font-Kanit"
                        sx={{
                            backgroundColor: '#B5D224',
                            fontSize: '18px',
                            height: '60px',
                            width: '300px',
                            color: 'black',
                            borderRadius: '15px',
                            '&:hover': { backgroundColor: '#B5D224' },
                        }}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {t('BUTTONS.NEXT')}
                    </Button>
                </Stack>
            </Card>
        </div>
    );
};

export default Invoice;
