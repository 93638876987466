const UnitCo2e = ({ unitStart = 'kgCO', unitEnd = 'eq' }: { unitStart?: string; unitEnd?: string }) => {
    return (
        <div className="font-medium">
            &nbsp; {unitStart}
            <sub className="b-[-0.25em]">2</sub>
            {unitEnd} &nbsp;
        </div>
    );
};

export default UnitCo2e;
