import React from 'react';
import { useTranslation } from 'react-i18next';
import DashboardLogo from '../../constants/img/Character_TGO_08.png';

interface HiLightDashboardProps {
    showSetting?: boolean;
    handleCreateProject: () => void;
    handleClickSetting?: () => void | undefined;
}

const HiLightDashboard: React.FC<HiLightDashboardProps> = ({ handleCreateProject, handleClickSetting, showSetting = false }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="columns is-mobile">{/* <h1 className="content-header">{t('DASHBOARD_PAGE.HEADER')}</h1> */}</div>
            <div className="columns is-mobile">{/* <h3 className="content-sub-header">{t('DASHBOARD_PAGE.SUBHEADER')}</h3> */}</div>
            {showSetting && (
                <div className="flex justify-end space-y-2 pb-6">
                    <button className="button button_settings" type="button" onClick={handleClickSetting}>
                        {t('BUTTONS.SETTING')}
                    </button>
                </div>
            )}
            <div className="columns is-mobile mt-12">
                <div className="column is-4 is-offset-6">
                    <div className="talk-bubble tri-right round btm-left-in">
                        <div className="talktext">
                            <p>{t('DASHBOARD_PAGE.CONTENT')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns is-mobile">
                <div className="column is-3 is-offset-1">
                    <button className="button button-dashboard is-2 is-success is-fullwidth App " type="button" onClick={handleCreateProject}>
                        {t('BUTTONS.CREATE_PROJECT')}
                    </button>
                    <br />
                    <br />
                </div>
                <div className="column is-4">
                    <img src={DashboardLogo} style={{ marginLeft: '40px' }} alt="logo" />
                </div>
            </div>
        </>
    );
};

export default HiLightDashboard;
