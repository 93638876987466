import { Box, Button, Card, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TablePagination } from '@mui/material';
import { useDeleteUser, useRoles, useUsers } from '@src/services/queries/useUsersQuery';
import { useEffect, useState } from 'react';
import { applyFilter, getComparator } from '../../../components/TableZeroCarbon/utils';

import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { ButtonAdd } from '@src/components/layout/admin/components/Element/ButtonAdd';
import { IRoles, UserCreate } from '@src/types';
import Swal from 'sweetalert2';

import Scrollbar from '@src/components/scrollbar';
import { config } from '@src/config';
import { useGlobalState } from '@src/context/GlobalStateContext';
import { formatDate } from '@src/utils/format-date-th';
import Iconify from '../../../components/iconify';
import TableHeadZero from '../../../components/TableZeroCarbon/TableHead';
import TableLoading from '../../../components/TableZeroCarbon/TableLoading';
import TableNoData from '../../../components/TableZeroCarbon/TableNoData';
import TableRowZeroCarbon from '../../../components/TableZeroCarbon/TableRow';
import TableToolBar from '../../../components/TableZeroCarbon/TableToolBar';
import { FormUser } from '../form-user';
const UserTable = () => {
    const { setUserUpdate } = useGlobalState();

    const [openForm, setOpenForm] = useState(false);
    const [page, setPage] = useState(0);
    const [optionRoles, setOptionRoles] = useState<IRoles[]>([]);
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<string>('createdAt');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [authType, setAuthType] = useState<string>('CREDENTIAL');
    const [updateUser, setUpdateUser] = useState<UserCreate | undefined>(undefined);

    const deleteUserMutation = useDeleteUser();
    const { data: roles } = useRoles();

    const handleSort = (event: any, id: any) => {
        const isAsc = orderBy === id && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(id);
    };

    const columns = [
        { id: '', label: 'NO.', align: 'center' },
        { id: 'firstName', label: 'ชื่อ' },
        { id: 'email', label: 'อีเมล' },
        { id: 'tel', label: 'เบอร์โทร' },
        { id: 'role', label: 'บทบาท' },
        { id: 'createdAt', label: 'วันที่สร้าง' },
        { id: '', label: 'เครื่องมือ' },
    ];

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilter = (event: any) => {
        setFilterName(event);
    };
    const { data, isLoading } = useUsers(authType, filterName, page + 1, rowsPerPage);

    useEffect(() => {
        if (roles) {
            setOptionRoles(roles);
        }
    }, [roles]);

    const users = data?.data || [];

    const filtered = applyFilter({
        inputData: users,
        comparator: getComparator(order, orderBy),
        filterName: '',
        keyField: ['firstName', 'lastName', 'email', 'tel'],
    });

    const notFound = !filtered.length && !!filterName;

    const onClickAddUser = () => {
        setOpenForm(true);
        setUpdateUser(undefined);
        setUserUpdate(undefined);
    };

    const onEditUser = (user: UserCreate) => {
        setUpdateUser(user);
        setUserUpdate(user);
        setOpenForm(true);
    };

    const handleCloseForm = () => {
        setUpdateUser(undefined);
        setOpenForm(false);
    };

    const TableRowBody = ({ index, rows }: { index: number; rows: UserCreate }) => {
        const { id, firstName, lastName, email, tel, role, createdAt } = rows;
        const onDelete = (id: number | undefined) => {
            Swal.fire({
                title: 'ต้องการลบผู้ใช้งานนี้?',
                text: 'คุณต้องการลบผู้ใช้งานนี้หรือไม่?',
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#B5D224',
                cancelButtonColor: '#d33',
                cancelButtonText: 'ยกเลิก',
                confirmButtonText: 'ยืนยัน',
                customClass: {
                    popup: 'popup-backend',
                },
            }).then((result: any) => {
                if (result.isConfirmed) {
                    deleteUserMutation.mutateAsync(id);
                }
            });
        };

        return (
            <>
                <TableCell
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    {index + 1}
                </TableCell>
                <TableCell scope="row" className="text-xs whitespace-nowrap text-left">
                    <div className="truncate max-w-72 flex flex-col" title={firstName}>
                        {`${firstName} ${lastName}`}
                    </div>
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                    <div className="truncate max-w-60">{email}</div>
                </TableCell>

                <TableCell>{tel}</TableCell>
                <TableCell>{role}</TableCell>
                <TableCell>{formatDate(createdAt ?? '')}</TableCell>
                <TableCell align="right">
                    <IconButton aria-label="edit" size="small" onClick={() => onEditUser(rows)}>
                        <EditTwoToneIcon />
                    </IconButton>

                    <IconButton aria-label="delete" size="small" onClick={() => onDelete(id)}>
                        <DeleteTwoToneIcon />
                    </IconButton>
                </TableCell>
            </>
        );
    };

    return (
        <>
            <FormUser open={openForm} defaultValue={updateUser} setOpenForm={setOpenForm} optionRoles={optionRoles} handleClose={handleCloseForm} />
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                <Box>
                    <Button onClick={() => setAuthType('CREDENTIAL')} variant="contained" color={authType === 'CREDENTIAL' ? 'success' : 'inherit'}>
                        <Iconify icon="carbon:credentials" sx={{ mr: 0.5 }} />
                        CREDENTIAL
                    </Button>
                    <Button onClick={() => setAuthType('GOOGLE')} variant="contained" color={authType === 'GOOGLE' ? 'success' : 'inherit'}>
                        <Iconify icon="flat-color-icons:google" sx={{ mr: 0.5 }} />
                        GOOGLE
                    </Button>
                    {/* <Button variant="contained" disabled>
            <Iconify icon="logos:apple" sx={{ mr: 0.5 }} />
            Apple
          </Button> */}
                </Box>
                <TableToolBar onSearch={handleFilter} ButtonAdd={<ButtonAdd text="เพิ่มผู้ใช้งาน" onClick={onClickAddUser} />} />
            </Stack>

            <Scrollbar>
                <Card>
                    <TableContainer component={Paper}>
                        <Table
                            sx={{
                                minWidth: {
                                    sm: 1700,
                                    md: 1600,
                                    lg: 1300,
                                },
                            }}
                            size={'small'}
                        >
                            <colgroup>
                                <col width="1%" />
                                <col width="20%" />
                                <col width="22%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="10%" />
                            </colgroup>
                            <TableHeadZero order={order} orderBy={orderBy} onRequestSort={handleSort} headLabel={columns} />

                            <TableBody sx={{ fontSize: '0.75rem' }}>
                                {isLoading && <TableLoading />}

                                {filtered.map((row, index) => (
                                    <TableRowZeroCarbon
                                        defaultChecked={false}
                                        key={index}
                                        rows={row}
                                        index={index}
                                        TableRowBody={<TableRowBody index={index} rows={row} />}
                                    />
                                ))}

                                {notFound && <TableNoData query={filterName} />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Scrollbar>
            <TablePagination
                count={data?.total || 0}
                onPageChange={handleChangePage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={config.rowsPerPageOptions}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default UserTable;
